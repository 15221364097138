<template>
  <post :post="postData"></post>
</template>

<script>
import Post from '@/components/Post'
import postData from '../mock/TermsOfService.json'

export default {
  name: 'Terms',
  components: {
    Post
  },
  data: () => ({
    postData: postData
  })
}
</script>

<style scoped>

</style>
